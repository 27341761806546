import { Controller } from "stimulus"
import $ from 'jquery';
import { template } from "lodash"

export default class extends Controller {

  connect() {
  }

  close() {
    document.getElementById("modal").innerHTML = "";
  }


  disconnect() {
  }
}