import { Controller } from "stimulus"
import * as echarts from 'echarts';
import Rails from "@rails/ujs";
import { fullGraphs } from "../mixins/full_graph";

import $ from 'jquery';

require("select2/dist/css/select2")

import Select2 from "select2"


export default class extends Controller {
  static targets = ['graphs', "startAt", "endAt", 'table']
  connect() {
    $('.league-search').select2();
    $(".league-search").on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
    fullGraphs(this)
    if (document.getElementById('full_let_graph').attributes._echarts_instance_) {
      return
    }
    var dates = [],
      totals = [],
      wins = [],
      ties = [],
      fails = []
    fetch("/match_results/full_graph.json")
      .then(response => response.text())
      .then(res => {
        var rs = JSON.parse(res)
        console.log(rs)
        rs.data.forEach(data => {
          dates.push(data[0])
          totals.push(data[1])
          wins.push(data[2])
          ties.push(data[3])
          fails.push(data[4])
        });
        this.loadGraph(dates, totals, wins, ties, fails, echarts.init(document.getElementById('full_let_graph')))
        this.goalGraph(rs.goals, rs.no_goals)
        this.winsGraph(rs.wins, rs.ties, rs.fails)
      })
  }

  select_league() {
  }

  search() {
    var dates = [],
      totals = [],
      wins = [],
      ties = [],
      fails = []
    fetch("/match_results/full_graph.json?start_at=" + this.startAtTarget.value + "&end_at=" + this.endAtTarget.value + "&league=" + $('.league-search').val())
      .then(response => response.text())
      .then(res => {
        var rs = JSON.parse(res)
        console.log(rs)
        rs.data.forEach(data => {
          dates.push(data[0])
          totals.push(data[1])
          wins.push(data[2])
          ties.push(data[3])
          fails.push(data[4])
        });
        this.loadGraph(dates, totals, wins, ties, fails, echarts.init(document.getElementById('full_let_graph')))
        this.goalGraph(rs.goals, rs.no_goals)
        this.winsGraph(rs.wins, rs.ties, rs.fails)
        this.loadData(rs.total, rs.first_goal, rs.second_goal, rs.no_goals, rs.wins, rs.ties, rs.fails)
      })
  }
  loadData(total, first_goal, second_goal, ungoal_total, win_total, tie_total, fail_total ) {
    return this.tableTarget.innerHTML = `<td class="px-6 py-4 text-center">
            ${total}
            </td>
            <td class="px-6 py-4 text-center">
             <span class="text-red-600 text-bold">${(first_goal > 0 ? first_goal / parseFloat(total) * 100 : 0).toFixed(1) }%</span>
             (${first_goal})
            </td>
            <td class="px-6 py-4 text-center">
             <span class="text-red-600 text-bold">${(second_goal > 0 ? second_goal / parseFloat(total) * 100 : 0).toFixed(1) }%</span>
             (${second_goal})
            </td>
            <td class="px-6 py-4 text-center">
              ${(total > 0 ? ungoal_total / parseFloat(total) * 100 : 0).toFixed(1)}%
              (${ungoal_total})
            </td>
            <td class="px-6 py-4 text-center">
             <span class="text-red-600 text-bold">${(total > 0 ? win_total / parseFloat(total) * 100 : 0).toFixed(1) }%</span>
             (${win_total})
            </td>
            <td class="px-6 py-4 text-center">
             ${(total > 0 ? tie_total / parseFloat(total) * 100 : 0).toFixed(1)}%
              (${tie_total})
            </td>
            <td class="px-6 py-4 text-center">
             ${(total > 0 ? fail_total / parseFloat(total) * 100 : 0).toFixed(1)}%
              (${fail_total})
            </td>
            `
  }

}