import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import * as echarts from 'echarts';

export default class extends Controller {
  static targets = ["startAt", "endAt"]
  connect() {
  }

  search() {
    console.log("srac")
    console.log(this.element)
    console.log(this.startAtTarget.value)
    Rails.ajax({
      type: "get",
      url: "/match_results/half_let",
      data: JSON.stringify({ start_at: this.startAtTarget.value, end_at: this.endAtTarget.value })
    })
  }

  onSuccess(e) {
    console.log("ee3", e.detail)
    console.log("----22")
    var myChart = echarts.init(document.getElementById('half_let_graph'));

    var option = {
      title: {
        text: '半场',
        subtext: '每日半场数据次数'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#283b56'
          }
        }
      },
      legend: {
        data: ['异常次数', '半场赢次', '半场平次', '半场输次']
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { readOnly: false },
          restore: {},
          saveAsImage: {}
        }
      },
      dataZoom: {
        show: false,
        start: 0,
        end: 100
      },
      xAxis: [{
        type: 'category',
        boundaryGap: true,
        data: ['2020-02-01', '2020-02-02', '2020-02-03']
      }],
      yAxis: [{
        type: 'value',
        scale: true,
        name: '次数',
        max: 80,
        min: 0
      }],
      series: [{
          name: '异常次数',
          type: 'line',
          xAxisIndex: 0,
          yAxisIndex: 0,
          smooth: true,
          showAllSymbol: true,
          symbol: 'emptyCircle',
          symbolSize: 15,
          data: [12, 13, 4]
        },
        {
          name: '半场赢次',
          type: 'bar',
          barBorderRadius: 5,
          barWidth: 10,
          xAxisIndex: 0,
          yAxisIndex: 0,
          itemStyle: {
            barBorderRadius: 5,
            color: new echarts.graphic.LinearGradient(
              14, 232, 20, 1, [
                { offset: 0, color: '#14c8d4' },
                { offset: 1, color: '#43eec6' }
              ]
            )
          },
          data: [1, 0, 4]
        },
        {
          name: '半场平次',
          type: 'bar',
          barWidth: 10,
          xAxisIndex: 0,
          yAxisIndex: 0,
          data: [12, 22, 20]
        },
        {
          name: '半场输次',
          type: 'bar',
          xAxisIndex: 0,
          barWidth: 10,
          yAxisIndex: 0,
          data: [31, 2, 0]
        },
      ]
    };

    myChart.setOption(option);

    var circle_chart = echarts.init(document.getElementById('circle'));
    var circle_goal =  echarts.init(document.getElementById('circle_goal'));
    var cirle_option = {
      title: {
        text: '半场输赢次数',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'left',
      },
      series: [{
        name: '半场输赢',
        type: 'pie',
        radius: '50%',
        data: [
          {
            value: 1048, name: '半场赢次数',
            itemStyle: {
              color: "#EC4818"
            }  },
          {
            value: 735, name: '半场平次数',
         itemStyle: {
              color: "#ECC618"
            }  },
          {
            value: 580, name: '半场输次数',
         itemStyle: {
              color: "#BFEC18"
            }  },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }]
    };
    circle_chart.setOption(cirle_option);

    var circle_goal_option = {
      title: {
        text: '半场是否进球',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        left: 'left',
      },
      series: [{
        name: '半场是否进球',
        type: 'pie',
        radius: '50%',
        data: [
          {
            value: 1048, name: '进球',
            itemStyle: {
              color: "#EC4818"
            }
          },
          {
            value: 735, name: '没有进球',
            itemStyle: {
             color: "#18ECB9"
            }}
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(200, 0, 0, 0.5)'
          }
        }
      }]
    };
    circle_goal.setOption(circle_goal_option);

  }
}