import { Controller } from "stimulus"
import $ from 'jquery';
import { template } from "lodash"

export default class extends Controller {
  static targets = ['list', 'orderTemplate']

  connect() {
    var self = this;
    $("#order_list").on('click', '.remove-order',  function(e){
      e.currentTarget.parentNode.parentNode.remove()
    });
    this.resourceTemplate = template(this.orderTemplateTarget.innerHTML);
    $("#addOrder").on('click', function(){
      self.add_field()
    })
  }

  disconnect() {
  }

  add_field() {
    const el = document.createElement("a");
    el.classList.add('flex');
    el.classList.add('flex-wrap');
    el.innerHTML = this.resourceTemplate();
    this.listTarget.appendChild(el);
  }
}
