import * as echarts from 'echarts';
export const halfLetGraphs = controller => {
  Object.assign(controller, {
    winsGraph(wins, ties, fails) {
      var circle_chart = echarts.init(document.getElementById('circle'));
      var cirle_option = {
        title: {
          text: '半场输赢数',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        series: [{
          name: '半场输赢',
          type: 'pie',
          radius: '50%',
          data: [{
              value: wins,
              name: '半场赢数',
              itemStyle: {
                color: "#EC4818"
              }
            },
            {
              value: ties,
              name: '半场平数',
              itemStyle: {
                color: "#ECC618"
              }
            },
            {
              value: fails,
              name: '半场输数',
              itemStyle: {
                color: "#BFEC18"
              }
            },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
      circle_chart.setOption(cirle_option);
    },
    goalGraph(goals, no_goals) {
      var circle_goal = echarts.init(document.getElementById('circle_goal'));
      var circle_goal_option = {
        title: {
          text: '半场是否进球比例',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        series: [{
          name: '半场是否进球',
          type: 'pie',
          radius: '50%',
          data: [{
              value: goals,
              name: '进球',
              itemStyle: {
                color: "#EC4818"
              }
            },
            {
              value: no_goals,
              name: '没有进球',
              itemStyle: {
                color: "#18ECB9"
              }
            }
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(200, 0, 0, 0.5)'
            }
          }
        }]
      };
      circle_goal.setOption(circle_goal_option);
    },
    loadGraph(dates, totals, wins, ties, fails, myChart) {

      var option = {
        title: {
          text: '半场',
          subtext: '每日半场数据统计'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        legend: {
          data: ['异常数', '半场赢', '半场平', '半场输']
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          data: dates
        }],
        yAxis: [{
          type: 'value',
          scale: true,
          name: '次数',
          // max: 600,
          min: 0
        }],
        series: [{
            name: '异常数',
            type: 'line',
            xAxisIndex: 0,
            yAxisIndex: 0,
            smooth: true,
            showAllSymbol: true,
            symbol: 'emptyCircle',
            symbolSize: 15,
            data: totals
          },
          {
            name: '半场赢',
            type: 'bar',
            barBorderRadius: 5,
            barWidth: 10,
            xAxisIndex: 0,
            yAxisIndex: 0,
            itemStyle: {
              barBorderRadius: 5,
              color: new echarts.graphic.LinearGradient(
                14, 232, 20, 1, [
                  { offset: 0, color: '#14c8d4' },
                  { offset: 1, color: '#43eec6' }
                ]
              )
            },
            data: wins
          },
          {
            name: '半场平',
            type: 'bar',
            barWidth: 10,
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: ties
          },
          {
            name: '半场输',
            type: 'bar',
            xAxisIndex: 0,
            barWidth: 10,
            yAxisIndex: 0,
            data: fails
          },
        ]
      };

      myChart.setOption(option);

    }
  });
};