import { Controller } from "stimulus"
import Rails from "@rails/ujs";
export default class extends Controller {
  connect() {
  }

  open_early(e) {
    let data = e.target.dataset
    window.open("/matches/" + data.id + "/early", "_blank", "top=100, left=100, scrollbars=no width=1280, height=800");
  }

  open_window(e) {
    let data = e.target.parentNode.parentNode.dataset
    window.open("/matches/" + data.match_id, "_blank", "top=100, left=100, scrollbars=no width=1230, height=800");
  }

  open_window_anchor(e) {
    let data = e.target.parentNode.parentNode.dataset;
    let an = e.target.dataset;
    if (e.target.nodeName == 'SPAN') {
      data = e.target.parentNode.parentNode.parentNode.dataset;
      an = e.target.parentNode.dataset
    }
    window.open("/matches/" + data.match_id + "#" + an.time, "_blank", "top=100, left=100, scrollbars=no width=1230, height=800");
  }

  open_children_window(e) {
    let data = e.target.parentNode.parentNode.dataset
    window.open("/matches/" + data.match_id + "/children", "_blank", "top=100, left=100, scrollbars=no width=1210, height=800");
  }

  open_alert_window(e) {
    let data = e.target.dataset
    window.open("/matches/" + data.match_id + "/alert", "_blank", "top=100, left=100, scrollbars=no width=1260, height=500");
  }

  open_h_let(e) {
    let data = e.target.parentNode.parentNode.parentNode.dataset
    window.open("/matches/" + data.match_id + "/odds?clazz=h_let", "_blank", "top=100, left=100, scrollbars=no width=760, height=800");
  }

  open_f_let(e) {
    let data = e.target.parentNode.parentNode.parentNode.dataset
    window.open("/matches/" + data.match_id + "/odds?clazz=f_let", "_blank", "top=100, left=100, scrollbars=no width=760, height=800");
  }

  open_h_size(e) {
    let data = e.target.parentNode.parentNode.parentNode.dataset
    window.open("/matches/" + data.match_id + "/odds?clazz=h_size", "_blank", "top=100, left=100, scrollbars=no width=760, height=800");
  }

  open_f_size(e) {
    let data = e.target.parentNode.parentNode.parentNode.dataset
    window.open("/matches/" + data.match_id + "/odds?clazz=f_size", "_blank", "top=100, left=100, scrollbars=no width=830, height=800");
  }

  open_f(e) {
    let data = e.target.parentNode.parentNode.dataset
    window.open("/matches/" + data.match_id + "/data?clazz=full", "_blank", "top=100, left=100, scrollbars=no width=720, height=800");
  }
  
  open_h(e) {
    let data = e.target.parentNode.parentNode.dataset
    window.open("/matches/" + data.match_id + "/data?clazz=half", "_blank", "top=100, left=100, scrollbars=no width=670, height=800");
  }
  
  open_odd(e) {
    let data = e.target.parentNode.parentNode.dataset
    window.open("/matches/" + data.match_id + "/data?clazz=double", "_blank", "top=100, left=100, scrollbars=no width=480, height=800");
  }

  open_corn(e) {
    let data = e.target.dataset
    window.open("/matches/" + data.match_id, "_blank", "top=100, left=100, scrollbars=no width=1230, height=800");
  }

  expand_odd(e) {
    var cls = e.target.dataset.cls;
    var x = document.getElementsByClassName(cls);
    for (let i = 0; i < x.length; i++) {
      x[i].classList.toggle('show')
    }
    if (document.getElementsByClassName(cls + "-open hidden").length > 0) {
      document.getElementsByClassName(cls + "-open")[0].classList.toggle('hidden')
    }
    if (document.getElementsByClassName(cls + "-close hidden").length > 0) {
      document.getElementsByClassName(cls + "-close")[0].classList.toggle('hidden')
    }
    document.getElementsByTagName('tbody')[0].classList.toggle(cls+'-tr')
    e.target.classList.toggle('hidden')
    var w = 0;
    if (document.getElementsByClassName("sub-f-let show").length > 0) {
      w += 210;
    }
    if (document.getElementsByClassName("sub-h-let show").length > 0) {
      w += 210;
    }
    if (document.getElementsByClassName("sub-f-size show").length > 0) {
    w += 210;
    }
    if (document.getElementsByClassName("sub-h-size show").length > 0) {
      w += 210;
    }
    document.getElementById('show_table').style.minWidth = 1230 + w + "px"
  }

  topMatch(e) {
    Rails.ajax({
      type: "post",
      url: "/matches/" + e.target.parentNode.parentNode.dataset.match_id + "/top",
      success: function(res) {
        if (res.top)  {
          e.target.innerText = "取消置顶";
          $(e.target.parentNode.parentNode).addClass("top");
          $(e.target.parentNode.parentNode).insertBefore( $(e.target.parentNode.parentNode.parentNode).find('tr:first-child') ); 
        } else {
          window.location.reload()
        }
      }
    })
    
  }
}