// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("@hotwired/turbo-rails")

import $ from 'jquery';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import "stylesheets/application"

import "controllers"

import { StreamActions } from "@hotwired/turbo"

StreamActions.toast = function() {
  const message = this.getAttribute("text")
  playAlert(message)
}

function playAlert(message) {
  if (('speechSynthesis' in window) && speechSynthesis.getVoices().length > 0) {
    SpeakVoice(message)
  }
}

const SpeakVoice = (msg = '') => {
  const speech = new SpeechSynthesisUtterance(msg)
  // 设置兼容中文
  const voices = window.speechSynthesis.getVoices()
  speech.voice = voices.filter(function (voice) {
    return voice.localService == true && voice.lang == 'zh-CN'
  })[0]
  window.speechSynthesis.speak(speech)
}


document.addEventListener('turbo:before-stream-render', function(event) {
  const fallbackToDefaultActions = event.detail.render
  event.detail.render = function (streamElement) {
    if (streamElement.action == "replace") {
      event.target.templateElement.content.querySelectorAll('.home-table').forEach(function(el, idx) {
        if (typeof gon !== 'undefined' && gon.top_match_id) {
          if (gon.top_match_id.includes(parseInt(el.dataset.match_id))) {
            el.classList.add("top");
            el.querySelectorAll(".top-btn")[0].innerText = "取消预警";
          }
        }
      })
      fallbackToDefaultActions(streamElement)
    } else {
      fallbackToDefaultActions(streamElement)
    }
  }
});
