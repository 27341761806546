import { Controller } from "stimulus"
import $ from 'jquery';
import { template } from "lodash"

export default class extends Controller {
  static targets = ['list']

  connect() {
  }

  close() {
    document.getElementById("user_modal").innerHTML = "";
  }

  disconnect() {
  }


}
