import Flatpickr from "stimulus-flatpickr";
// import a theme (could be in your main CSS entry too...)
import "flatpickr/dist/themes/dark.css";

// import the translation files and create a translation mapping
import { Mandarin } from "flatpickr/dist/l10n/zh.js";
import Rails from "@rails/ujs";
// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {


  initialize() {
    //set the locale and also sets the global flatpickr settings
    this.config = {
      locale: Mandarin,
      altInput: true,
      altFormat: "Y-m-d",
      altInput: true,
      showMonths: 1,
      animate: false
    };
  }

  // automatically submit form when a date is selected
  change(selectedDates, dateStr, instance) {
    const form = this.element.closest("form");
    // Rails.fire(form, "submit");
  }


}